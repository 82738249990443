<template>
    <div class="col-12 locations">
        <p>Member Level</p>
        <b-form-select v-model="selectedMemberLevel" class="select-location" @change="updateClientMemberLevel">
            <option v-for="ml in memberLevels" :value="ml.slug" 
                :class="{selected: ml.slug == selectedMemberLevel.slug}">
                {{ ml.name }}
            </option>
        </b-form-select>        
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";

export default {
    name: "ClientMemberLevelDropdown",
    props: {
        clientMemberLevel: {
            type: Object
        },
        clientId: {
            type: Number
        }
    },    
    data() {
        return {
            selectedMemberLevel: this.clientMemberLevel.slug,
            memberLevels: []
        };
    },
    mounted() {
        this.getClientMemberLevels();
    },
    methods: {
        async getClientMemberLevels() {
            const payload = { user_id: this.$store.getters.user_id };
            let uri = `${config.api_env}/application/api-hc/client-member-levels`;

            let res = await axios.post(uri, payload, config.options).then(response => {
                this.memberLevels = response.data.client_member_levels
            },(error) => {
                console.log(error);
            });    
        },
        async updateClientMemberLevel() {
            const payload = { 
                user_id: this.$store.getters.user_id,
                level_identifier: this.selectedMemberLevel 
            };

            let uri = `${config.api_env}/application/api/u/user/update-member-level`;

            let res = await axios.post(uri, payload, config.options).then(response => {
                if (response.data.success) {
                    // alert(response.data.message);
                }
            },(error) => {
                console.log(error);
            });
        }
    }   
}
</script>